.Card-mine-row-flexed {
  display: flex;
  /* flex-direction: row; */
  margin: 25px 0;
  /* justify-content: space-around; */
}

.Card-mine-row-flexed > div {
  /* flex: 0 0 31%;
    max-width: 31%; */
}

.Card-mine-row-flexed > div {
  width: calc(33.333% - 4px);
  margin: 2px;
}

.Card-mine-row-flexed {
  justify-content: flex-start;
  flex-flow: wrap;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  /* height: 34px; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #21f360;
}

input:focus + .slider {
  box-shadow: 0 0 1px #21f360;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch .slider.round {
  border-radius: 30px;
  width: 33px;
  height: 20px;
}

.switch input:checked + .slider:before {
  transform: translate(15px, -50%);
}

.switch .slider:before {
  height: 10px;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.add-new-btn {
  background-color: #81246c;
  border-radius: 20px;
  color: white !important;
  margin-bottom: 15px;
}
.occasions .table-responsive p {
  color: #81246c;
  margin: 0;
}

.languages .table-responsive p {
  color: #81246c;
  margin: 0;
}
.page-heading {
  margin-top: 15px;
}

.loader-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 400px !important;
  width: 100% !important;
}

.table-headings td {
  width: 150px !important;
}
